import {AureliaReactReportWrapper} from "$pages/reports-react/AureliaReactReportWrapper";
import DryRunReport from "$pages/reports-react/dry-run-report/dry-run-report";

class DryRunReportReactWrapper extends AureliaReactReportWrapper {
  constructor() {
    super(DryRunReport)
  }
}

export default DryRunReportReactWrapper
