import React, {FC, HTMLAttributes} from "react";
import {
  DryRunReportDocument,
  DryRunReportDownloadDocument,
  DryRunReportDownloadQuery,
  DryRunReportQueryVariables,
  DryRunReportQuery,
  Reports, 
  SortDirection
} from "$typings/graphql-codegen";
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {ColumnDefinition} from "$pages/reports-react/column-definition";
import { isFiniteNumber, isNumber } from '$lib/numberHelpers';
import {linkToTankDetails} from "$lib/linkHelper";
import {formatDate} from "$lib/dateHelpers";
import {SortObject, useGenericReport} from "$pages/reports-react/generic-report";
import {ReportGridDataHook} from "$pages/reports-react/generic-report-hooks";
import {ColumnWidth} from "$pages/reports-react/ColumnWidth";
import {getSession, getUserFeatures} from "../../../config/sessionService";

type ReportResult = DryRunReportQuery
type ReportVariables = DryRunReportQueryVariables
type ReportData = DryRunReportQuery["reports"]["dryRun"]["data"][0]
type ReportDownloadResult = DryRunReportDownloadQuery

enum ColumnKey {
  SiteAlias = 'siteAlias',
  SiteAliasLink = 'siteAliasLink',
  AccessTagName = 'accessTagName',
  ShipTo = 'shipTo',
  SoldTo = 'soldTo',
  CustomerRef = 'customerRef',
  ProductLanguageKey = 'productLanguageKey',
  ProductNameString = 'productNameString',
  DryRunStartTime = 'dryRunStartTime',
  RefillTimeStamp = 'refillTimeStamp',
  DryRunDuration = 'dryRunDuration',
  DryRunDurationString = 'dryRunDurationString',
  LostSalesAmount = 'lostSalesAmount'
}

interface FormattedReportData extends ReportData {
  [ColumnKey.SiteAliasLink]: JSX.Element
  [ColumnKey.ProductNameString]: string
  [ColumnKey.DryRunDurationString]: string
}

const DryRunReport: FC<HTMLAttributes<HTMLElement>> = () => {
  const [t] = useCaseInsensitiveTranslation()
  const Title: string = t('UI_Reports_DryRuns')
  const Filename: string = 'dry-run-report'
  const features = getUserFeatures()
  const session = getSession()
  
  const DefaultSortSettings: SortObject = {
    sortColumnKey: ColumnKey.SiteAlias,
    sortDirection: SortDirection.Asc
  }
  
  const ColumnDefinitions: ColumnDefinition<FormattedReportData>[] = [
    new ColumnDefinition<FormattedReportData>(
      t('UI_Reports_SiteName'),
      (row: FormattedReportData) => row[ColumnKey.SiteAliasLink],
      ColumnKey.SiteAlias,
      ColumnWidth.Common.SiteName
    ),
    new ColumnDefinition<FormattedReportData>(
      t('UI_Reports_Customer'),
      (row: FormattedReportData) => row[ColumnKey.AccessTagName] ?? '',
      ColumnKey.AccessTagName,
      ColumnWidth.Common.AccessTag
    ),
    new ColumnDefinition<FormattedReportData>(
      t('UI_Reports_ShipTo'),
      (row: FormattedReportData) => row[ColumnKey.ShipTo] ?? '',
      ColumnKey.ShipTo,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<FormattedReportData>(
      t('UI_Reports_SoldTo'),
      (row: FormattedReportData) => row[ColumnKey.SoldTo] ?? '',
      ColumnKey.SoldTo,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<FormattedReportData>(
      t('UI_Common_CustomerRef'),
      (row: FormattedReportData) => row[ColumnKey.CustomerRef] ?? '',
      ColumnKey.CustomerRef,
      ColumnWidth.Generic.Medium
    ),
    new ColumnDefinition<FormattedReportData>(
      t('UI_Reports_Product'),
      (row: FormattedReportData) => row[ColumnKey.ProductNameString] ?? '',
      ColumnKey.ProductNameString,
      ColumnWidth.Common.ProductName
    ),
    new ColumnDefinition<FormattedReportData>(
      t('UI_Reports_DryRun_Start'),
      (row: FormattedReportData) => formatDate(row[ColumnKey.DryRunStartTime], true, '.') ?? '',
      ColumnKey.DryRunStartTime,
      ColumnWidth.Generic.DateTime
    ),
    new ColumnDefinition<FormattedReportData>(
      t('UI_Reports_Refill_Start'),
      (row: FormattedReportData) => formatDate(row[ColumnKey.RefillTimeStamp], true, '.') ?? '',
      ColumnKey.RefillTimeStamp,
      ColumnWidth.Generic.DateTime
    ),
    new ColumnDefinition<FormattedReportData>(
      t('UI_Reports_DryRun_Length_Days'),
      (row: FormattedReportData) => row[ColumnKey.DryRunDurationString] ?? '',
      ColumnKey.DryRunDuration,
      ColumnWidth.Generic.Shorter
    ),
  ]

  const isSuperUser = session.currentUser.isSuperUser ?? false
  const hasReadAccess: boolean = features.reportDryRunsLostSales.read ?? false
  if (isSuperUser || hasReadAccess) {
    ColumnDefinitions.push(
      new ColumnDefinition<FormattedReportData>(
        t('UI_Reports_DryRun_Lost_Amount'),
        (row: FormattedReportData) => row[ColumnKey.LostSalesAmount] ?? '',
        ColumnKey.LostSalesAmount,
        ColumnWidth.Generic.Shorter,
      )
    )
  }
  
  function toFormattedReportData(data: ReportData): FormattedReportData {
    const formatted = {...data} as FormattedReportData
    
    // Site Alias
    formatted[ColumnKey.SiteAliasLink] = <></>
    if (data.siteId !== null) {
      const siteIdIsNumber: boolean = isNumber(data.siteId)
      const siteChannelId: boolean = isNumber(data.siteChannelId)
      if (siteIdIsNumber && siteChannelId) {
        const url: string = linkToTankDetails(data.siteId as number, data.siteChannelId)
        formatted[ColumnKey.SiteAliasLink] = <a href={url}>{ data.siteAlias }</a>
      }
    }
    
    // Product
    formatted[ColumnKey.ProductNameString] = t(formatted.productLanguageKey ?? '')
    
    // Dry Run Duration
    if (data.dryRunDuration) {
      const dryRunDuration: number = data.dryRunDuration
      const duration: number = isNumber(dryRunDuration) && isFiniteNumber(dryRunDuration) ? dryRunDuration : -1;

      if (duration <= 0) {
        formatted[ColumnKey.DryRunDurationString] = ''
      } else {
        formatted[ColumnKey.DryRunDurationString] = Math.round(duration).toFixed(0)
      }
    }
    
    return formatted
  }
  
  function dataSelector(result: ReportResult): FormattedReportData[] {
    const data: ReportData[] = (result?.reports?.dryRun?.data ?? []) as ReportData[]
    return data.map(toFormattedReportData)
  }
  
  function dataCounter(result: ReportResult): number {
    return dataSelector(result).length
  }
  
  function downloadUrlSelector(result: ReportDownloadResult): string {
    return result?.reports?.dryRun?.blobUrl ?? ''
  }
  
  const { GenericReport } = useGenericReport<
    ReportResult,
    ReportVariables,
    ReportData,
    ReportDownloadResult
  >()
  
  function createTitle(data: ReportGridDataHook<ReportData>, variables?: ReportVariables): JSX.Element {
    const title: JSX.Element = <div>{ Title }</div>
    let subTitle: JSX.Element | null = null
    if (variables?.input) {
      const n: number = data.data?.length ?? 0
      subTitle = <div>{n} dry runs</div>
    }
    const displaySubTitle = variables?.input && !data.isLoading
    return (
      <div className="flex flex-col gap-4">
        { title }
        { displaySubTitle && subTitle }
      </div>
    )
  }
  
  return <GenericReport
    titleCallback={createTitle}
    reportEnum={Reports.DryRun}
    mainQueryDocument={DryRunReportDocument}
    downloadQueryDocument={DryRunReportDownloadDocument}
    columnDefinitions={ColumnDefinitions}
    dataSelector={dataSelector}
    dataCounter={dataCounter}
    defaultSortSettings={DefaultSortSettings}
    downloadUrlSelector={downloadUrlSelector}
    downloadFilename={Filename}
    allowRunningUnfiltered={false} />
}

export default DryRunReport
